import type { ComponentType } from 'react';
import { useEffect, useState } from 'react';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import type { RootStateOrAny } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { useAsync } from 'react-use';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { DefaultSeo } from 'next-seo';
import { BrowserTracing } from '@sentry/browser';
import * as Sentry from '@sentry/react';

import InsiderTrackingUser from 'src/components/InsiderTrackingUser';
import MainLayout from 'src/components/Layout/MainLayout';
import ScriptC from 'src/components/Layout/Script';
import TrackSuccessPayment from 'src/components/TrackSuccessPayment';
import METADATA from 'src/constants/metadata';
import { DefaultSEO } from 'src/constants/seo';
import ErrorPage from 'src/containers/Custom/ErrorPage';
import wrapper from 'src/redux';
import { actionUserListRelationship, actionUserLogged, actionUserProfile } from 'src/redux/actions';
import { resetInsiderObject } from 'src/utils/analytics/insider';
import AuthStorage from 'src/utils/auth-storage';

export type NextPageWithLayout = NextPage & {
	getLayout?: ComponentType<any>;
};

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout;
};

require('src/styles/index.less');

const sentryEnv = process.env.SENTRY_ENV;
const sentryDsn = process.env.SENTRY_DSN;

if (sentryEnv && sentryDsn) {
	Sentry.init({
		dsn: sentryDsn,
		environment: sentryEnv,
		integrations: [new BrowserTracing()],
		tracesSampleRate: 1.0,
	});
}

const MyApp = (props: AppPropsWithLayout) => {
	const { Component, pageProps } = props;
	const dispatch = useDispatch();
	const router = useRouter();
	const { auth, profile } = useSelector((state: RootStateOrAny) => state);
	const [queryClient] = useState(
		() => new QueryClient({ defaultOptions: { queries: { staleTime: 60 * 1000 } } }),
	);

	const Layout = Component.getLayout ?? MainLayout;

	const asPathSplit = router.asPath.split('?');
	let canonicalPath = asPathSplit[0].split('#')[0];
	const qCanonicalParam = asPathSplit[1];

	if (qCanonicalParam?.length > 0) {
		let searchParams = new URLSearchParams(qCanonicalParam);

		if (searchParams.get('page')) {
			canonicalPath = canonicalPath + '?' + `page=${searchParams.get('page')}`;
		}
	}

	if (pageProps?.pageHeaderContent?.canonical) {
		canonicalPath = pageProps?.pageHeaderContent?.canonical;
	}

	let combinedProps = { ...pageProps };

	useAsync(async () => {
		(await import('src/utils/layout-mobile')).default(router);
		(await import('src/utils/header-mp')).default(router);
		if (AuthStorage.loggedIn && ['/signin', '/signin/forgotpin'].includes(router.pathname)) {
			await router.push('/');
		}
	}, []);

	useAsync(async () => {
		if (AuthStorage.loggedIn) {
			(await import('src/utils/func')).listenFirebaseNotification();
		}
	}, [AuthStorage.loggedIn]);

	useAsync(async () => {
		if (!AuthStorage.loggedIn && pageProps?.protectedRoute && router.isReady) {
			await router.push(`/signin?return_url=${encodeURIComponent(router.asPath)}`);
		}
	}, [AuthStorage.loggedIn, router.isReady]);

	useAsync(async () => {
		if (AuthStorage.loggedIn && !auth.userLogged) {
			await dispatch(await actionUserLogged());
		}

		const userUid = auth?.userLogged?.data?.record?.uid;
		if (AuthStorage.loggedIn && !auth.user && userUid) {
			await dispatch(await actionUserProfile(userUid));
		}
	}, [AuthStorage.loggedIn, auth.userLogged]);

	useAsync(async () => {
		if (
			AuthStorage.loggedIn &&
			router.isReady &&
			['/profil', '/resep'].includes(router.pathname.slice(0, 7)) &&
			![
				'/profil/menunggu-pembayaran/detail/[invoice]',
				'/profil/daftar-transaksi/detail/[invoice]',
				'/profil/daftar-transaksi/receipt/[invoice]',
				'/profil/wallet/setting',
				'/profil/sehat-squad/info',
				'/profil/klikpoin/point-history',
				'/profil/klikpoin/redeem-voucher',
				'/profil/klikpoin/redeem-voucher/[slug]',
				'/profil/klikpoin/voucher',
				'/profil/klikpoin/voucher/[slug]',
			].includes(router.pathname)
		) {
			if (
				profile.userSelected?.user_uid &&
				profile.userSelected?.is_verified &&
				router.pathname === '/profil'
			) {
				await dispatch(await actionUserProfile(profile.userSelected?.user_uid));
			}

			if (!profile.userSelected?.user_uid && !auth.userListRelationship) {
				await dispatch(await actionUserListRelationship());
			}
		}
	}, [profile.userSelected, router]);
	useEffect(() => {
		const handleRouteChange = () => {
			resetInsiderObject(['product', 'page', 'listing']);
		};

		router.events.on('routeChangeStart', handleRouteChange);

		return () => {
			router.events.off('routeChangeStart', handleRouteChange);
		};
	}, []);

	if (pageProps?.isError) {
		return (
			<ErrorPage
				title="Terjadi Kesalahan"
				description="Oops sepertinya akun Klikdokter kamu belum terdaftar pada sistem kami."
			/>
		);
	}

	return (
		<>
			<QueryClientProvider client={queryClient}>
				<Hydrate state={pageProps.dehydratedState}>
					<div>
						<Layout {...combinedProps} router={router}>
							<>
								<DefaultSeo
									canonical={METADATA.WEB_URL + canonicalPath}
									openGraph={{ url: METADATA.WEB_URL + router.asPath }}
									{...DefaultSEO}
								/>
								<ScriptC />
								<Component {...combinedProps} router={router} />
								<ReactQueryDevtools initialIsOpen={false} />
								<InsiderTrackingUser />
								<TrackSuccessPayment />
							</>
						</Layout>
					</div>
				</Hydrate>
			</QueryClientProvider>
		</>
	);
};

export default wrapper.withRedux(MyApp);
