import { Typography } from 'antd';

import ImageC from 'src/components/Image';

import classes from './HeaderSearchInfo.module.less';

const HeaderSearchInfo = () => {
	return (
		<div className={classes.wrapper}>
			<ImageC src="/icons/search.svg" height={40} width={40} />
			<Typography.Paragraph className={classes.description}>
				Ketik
				<Typography.Text className={classes.detail}> minimal 3 karakter </Typography.Text>
				untuk melakukan pencarian
			</Typography.Paragraph>
		</div>
	);
};

export default HeaderSearchInfo;
