import { List, Typography } from 'antd';

import { SearchModuleLabel } from 'src/interface';

import classes from './HeaderSearchListItem.module.less';

type Props = {
	label: SearchModuleLabel;
};

const HeaderSearchListItem = (props: Props) => {
	const { label } = props;
	const { type, content } = label;

	if (type === 'detail-search') {
		return (
			<List.Item className={classes['detail-search']}>
				<span
					className={classes.title}
					dangerouslySetInnerHTML={{ __html: content?.title || '' }}
				/>
				<Typography.Text className={classes.description}>{content?.description}</Typography.Text>
			</List.Item>
		);
	}

	return (
		<List.Item className={classes['simple-search']}>
			<span className={classes.title} dangerouslySetInnerHTML={{ __html: content?.title || '' }} />
		</List.Item>
	);
};

export default HeaderSearchListItem;
