import { useQuery } from 'react-query';

import { PAYMENT, WAITING_TRANSACTION } from 'src/constants/services';
import { fetchApiPayment } from 'src/utils/fetch-api';

const getWaiting = (type: string) => {
	return fetchApiPayment({
		url: `${PAYMENT.service}/${WAITING_TRANSACTION.service}/${type}`,
		options: { method: 'POST' },
		payload: { type },
	});
};

export const useCountWaiting = () => {
	return useQuery('waiting_count', () => getWaiting(WAITING_TRANSACTION.model.count));
};
