import { useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useTypewriter } from 'react-simple-typewriter';
import { useRouter } from 'next/router';
import { Form, Grid, Input } from 'antd';
import classNames from 'classnames';

import ImageC from 'src/components/Image';
import HeaderSearchDrawer from 'src/components/Layout/Header/HeaderSearch/HeaderSearchDrawer';
import HeaderSearchResults from 'src/components/Layout/Header/HeaderSearch/HeaderSearchResults';
import { SEARCH_MIN_CHAR, SEARCH_MODULES } from 'src/constants/search';
import {
	useDefaultGlobalSearch,
	useHealthServiceSearch,
	useKlikstoreMasterSearch,
	useMedicalFacilitySearch,
	useMeetDoctorSearch,
	useSearchSuggestions,
} from 'src/hooks';
import { SearchModule } from 'src/interface';
import useComponentVisible from 'src/utils/hook/useComponentVisible';
import useDebounce from 'src/utils/hook/useDebounce';

import KlikstoreSearchResult from './KlikstoreSearchResult';

import classes from './index.module.less';

const HeaderSearchC = (props: any) => {
	const r = useRouter();
	const location = useSelector((state: RootStateOrAny) => state.auth.userLocation);
	const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

	const { xs, sm, md, lg } = Grid.useBreakpoint();
	const responsive = (xs || sm || md) && !lg;

	const [search, setSearch] = useState<string>('');
	const query = useDebounce(search, 500);

	const [module, setModule] = useState<SearchModule>(SEARCH_MODULES.ASK_DOCTOR);
	const [activeTab, setActiveTab] = useState<string>(SEARCH_MODULES.ASK_DOCTOR.tabs[0].name);

	const defaultGlobalRecords = useDefaultGlobalSearch({
		query,
		location,
		activeTab,
		enabled: module.name === 'default-global',
	});

	const isHomeModule = r.pathname === '/';
	const isAskDoctorModule = r.pathname.includes('/tanya-dokter');
	const askDoctorRecords = useSearchSuggestions(query, 'ask-doctor', module.name === 'ask-doctor');

	const isMeetDoctorModule = r.pathname.includes('/temu-dokter');
	const meetDoctorRecords = useMeetDoctorSearch({
		query,
		location,
		enabled: module.name === 'meet-doctor',
	});

	const isHealthServiceModule = r.pathname.includes('/layanan');
	const healthServiceRecords = useHealthServiceSearch({
		query,
		location,
		enabled: module.name === 'health-service',
	});

	const isMedicalFacilityModule = [
		'/klinik',
		'/klinik/[slug]',
		'/klinik/[slug]/[service]',
		'/lab',
		'/lab/[slug]',
		'/lab/[slug]/[service]',
		'/rumah-sakit',
		'/rumah-sakit/[slug]',
		'/rumah-sakit/[slug]/[service]',
		'/faskes',
	].includes(r.pathname);
	const medicalFacilityRecords = useMedicalFacilitySearch({
		query,
		location,
		enabled: module.name === 'medical-facility',
	});

	const isCategoryHovered = props.isCategoryHovered;
	const isKlikstoreModule = props.isKalstoreModule;
	const klikstoreSearchRecords = useKlikstoreMasterSearch({
		query,
		location,
		enabled: module.name === 'klikstore',
	});
	const { text: textPlaceholderAnimate } = useTypewriter({
		words: isHomeModule ? ['Faskes', 'Layanan', 'Spesialis', 'Dokter'] : [''],
		loop: isHomeModule ? 0 : undefined,
	});

	const placeholder =
		isAskDoctorModule ||
		isMeetDoctorModule ||
		isHealthServiceModule ||
		isMedicalFacilityModule ||
		isKlikstoreModule
			? module.placeholder
			: `Cari ${isHomeModule ? textPlaceholderAnimate : 'di KlikDokter'}`;

	useEffect(() => {
		if (r.isReady) {
			if (isAskDoctorModule) {
				setActiveTab(SEARCH_MODULES.ASK_DOCTOR.tabs[0].name);
				setModule(SEARCH_MODULES.ASK_DOCTOR);
			} else if (isMeetDoctorModule) {
				setActiveTab(SEARCH_MODULES.MEET_DOCTOR.tabs[0].name);
				setModule(SEARCH_MODULES.MEET_DOCTOR);
			} else if (isHealthServiceModule) {
				setActiveTab(SEARCH_MODULES.HEALTH_SERVICE.tabs[0].name);
				setModule(SEARCH_MODULES.HEALTH_SERVICE);
			} else if (isMedicalFacilityModule) {
				setActiveTab(SEARCH_MODULES.MEDICAL_FACILITY.tabs[0].name);
				setModule(SEARCH_MODULES.MEDICAL_FACILITY);
			} else if (isKlikstoreModule) {
				setModule(SEARCH_MODULES.KLIKSTORE);
			} else {
				setActiveTab(SEARCH_MODULES.DEFAULT_GLOBAL.tabs[0].name);
				setModule(SEARCH_MODULES.DEFAULT_GLOBAL);
			}
		}
	}, [r.isReady, r.pathname]);

	useEffect(() => {
		if (r.query.keyword) {
			setSearch(r.query.keyword as string);
		}
		setIsComponentVisible(false);
	}, [r]);

	const getDataRecords = () => {
		if (isAskDoctorModule) return askDoctorRecords;
		if (isMeetDoctorModule) return meetDoctorRecords;
		if (isHealthServiceModule) return healthServiceRecords;
		if (isMedicalFacilityModule) return medicalFacilityRecords;
		if (isKlikstoreModule) return klikstoreSearchRecords;
		return defaultGlobalRecords;
	};

	const handleChangeSearch = (e: any) => {
		const value = e.target.value;
		setSearch(value);
		setIsComponentVisible(value?.length >= SEARCH_MIN_CHAR.FOR_INFO);
	};

	const handleClickSearch = () => {
		const showSearch = responsive ? true : query?.length >= SEARCH_MIN_CHAR.FOR_INFO;
		setIsComponentVisible(showSearch);
	};
	const handleBlurSearch = () => {
		if (isKlikstoreModule) {
			setTimeout(() => {
				const showSearch = responsive ? true : false;
				setIsComponentVisible(showSearch);
			}, 300);
		}
	};

	const renderSearchResult = () => {
		if (isKlikstoreModule) {
			return (
				<KlikstoreSearchResult
					isLoading={klikstoreSearchRecords.isLoading || klikstoreSearchRecords.isPreviousData}
					isComponentVisible={isComponentVisible && !isCategoryHovered}
					query={query}
					module={module}
					records={getDataRecords()}
					onKlikNowProductSelect={props.onKlikNowProductSelect}
				/>
			);
		}
		return (
			<div ref={ref}>
				<HeaderSearchResults
					isComponentVisible={isComponentVisible && !isCategoryHovered}
					query={query}
					module={module}
					records={getDataRecords()}
					activeTab={activeTab}
					setActiveTab={setActiveTab}
				/>
			</div>
		);
	};

	const onEnterSearch = () => {
		if (isKlikstoreModule && search.length >= 3) {
			r.push(`/kalstore/search?keyword=${search}&page=1`);
			return;
		}
	};

	return (
		<>
			<div className={classNames(classes.wrapper, { [classes.input]: !responsive })}>
				<Form autoComplete="off" className={classes.search} onClick={handleClickSearch}>
					<Input
						className={classNames({ [classes.focus]: isComponentVisible && !isCategoryHovered })}
						name="search"
						size="large"
						placeholder={placeholder}
						value={search}
						onPressEnter={onEnterSearch}
						onClick={handleClickSearch}
						onChange={handleChangeSearch}
						onBlur={handleBlurSearch}
						prefix={
							<ImageC
								alt="Icon Search"
								src="/icons/search.svg"
								height={responsive ? 16 : 24}
								width={responsive ? 16 : 24}
							/>
						}
					/>
				</Form>

				{responsive ? (
					<HeaderSearchDrawer
						isComponentVisible={isComponentVisible}
						setIsComponentVisible={setIsComponentVisible}
						placeholder={placeholder}
						search={search}
						query={query}
						setSearch={setSearch}
						module={module}
						records={getDataRecords()}
						activeTab={activeTab}
						setActiveTab={setActiveTab}
						isKlikstoreModule={isKlikstoreModule}
						onKlikNowProductSelect={props.onKlikNowProductSelect}
					/>
				) : (
					renderSearchResult()
				)}
			</div>
		</>
	);
};

export default HeaderSearchC;
