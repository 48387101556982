import { Card } from 'antd';
import classNames from 'classnames';

import { CardCProps } from 'src/interface/Layout/Card';

import classes from './index.module.less';

const CardC = (props: CardCProps) => {
	const { className, sticky, children, ...cardProps } = props;
	return (
		<Card
			{...cardProps}
			className={classNames(classes.card, className, { [classes.sticky]: sticky })}
		>
			{children}
		</Card>
	);
};

export default CardC;
