import Link from 'next/link';
import classNames from 'classnames';

import Image from 'src/components/Image';
import { KlikStoreCartProduct } from 'src/interface';
import { getDefaultProductImg } from 'src/utils/func';
import { textAmount } from 'src/utils/func/format-amount';
import { cleanSlug, renderDiscountPercentage } from 'src/utils/func/klikstore';

import classes from './index.module.less';

interface ProductProps {
	store: any;
	product: KlikStoreCartProduct;
}

const Product = ({ store, product }: ProductProps) => {
	const quantity = parseInt(product.quantity);
	const discountPercent = renderDiscountPercentage(parseFloat(product.discount_percentage ?? '0'));

	const stock = parseInt(product.stock);

	const isMerchantActive = (store.status ?? 1) === 1;
	const isActive =
		(product.status ?? 1) === 1 &&
		(product.is_active ?? 1) === 1 &&
		(product.merchant_product_status ?? 1) === 1;
	const isAvailable = stock > 0;

	const isDisabled = !isMerchantActive || !isActive || !isAvailable;

	const ProductStatus = () => {
		if (!isActive) {
			return (
				<div className={classNames(classes.remainingQuantity, classes['out-of-stock'])}>
					Produk Tidak Aktif
				</div>
			);
		} else if (stock === 0) {
			return (
				<div className={classNames(classes.remainingQuantity, classes['out-of-stock'])}>
					Stok Tidak Tersedia
				</div>
			);
		} else if (stock < 10 || quantity === stock) {
			return <div className={classes.remainingQuantity}>Sisa stok {stock}</div>;
		} else {
			return null;
		}
	};

	return (
		<div className={classNames(classes.product, { [classes.disabled]: isDisabled })}>
			<Link
				href={cleanSlug(`/kalstore/s/${store?.slug ?? ''}/${product?.slug ?? ''}`)}
				style={{ color: 'unset' }}
			>
				<div className={classes.wrapper}>
					<div className={classes.detailWrapper}>
						<div className={classes.image}>
							<Image
								src={product.image}
								defaultSrc={getDefaultProductImg()}
								alt={product.name}
								width={48}
								height={48}
							/>
						</div>

						<div className={classes.detail}>
							<div className={classes.name}>
								<div>{product.name}</div>
								<div className={classes.price}>
									{discountPercent > 0 && (
										<>
											<div className={classes.percentage}>{discountPercent}%</div>
											<div className={classes.originalPrice}>
												{textAmount(product.original_price)}
											</div>
										</>
									)}
									<div>
										{quantity} x {textAmount(product.price ?? 0)}
										<span className={classes['per-product']}>/produk</span>
									</div>
								</div>
								{isMerchantActive && <ProductStatus />}
							</div>
						</div>
					</div>
				</div>
			</Link>
		</div>
	);
};

export default Product;
