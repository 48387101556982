import { Grid, Typography } from 'antd';

import ImageC from 'src/components/Image';

import WrapNotif from './WrapNotif';

import classes from '../../UserMenu/UserNotificationMenu.module.less';

const PromoMenu = (props: any) => {
	const { xs } = Grid.useBreakpoint();

	return (
		<>
			<WrapNotif cardStatus={false} onClickNotifiation={props.onClick}>
				<div className={classes['img-notif-promo']}>
					<ImageC
						src={props.promo_data.banner_image}
						height={xs ? 33 : 32}
						width={xs ? 86 : 84}
						alt={props.title}
						objectFit="cover"
					/>
				</div>

				<div className={`${classes['wrapp-info-notif']}`}>
					<Typography.Title className={classes['text-header']}>
						{props.promo_data.title}
					</Typography.Title>

					<Typography.Paragraph className={classes['text-paragraph']}>
						{props.promo_data.message}
					</Typography.Paragraph>

					<Typography.Text className={classes['text-label']}>
						{props.promo_data.date_created}
					</Typography.Text>
				</div>
			</WrapNotif>
		</>
	);
};

export default PromoMenu;
