import { Tabs } from 'antd';

import classes from '../UserMenu/UserNotificationMenu.module.less';
const TabMenuNotification = (props: any) => {
	return (
		<Tabs
			activeKey={props.activeKey}
			onChange={props.OnChangeTab}
			className={classes['tab-wrapper']}
		>
			<Tabs.TabPane tab={props.nameOfTab} key={props.keyOfTab} />
		</Tabs>
	);
};

export default TabMenuNotification;
