import Link from 'next/link';
import { useRouter } from 'next/router';
import { ConfigProvider, List, Tabs } from 'antd';

import CardC from 'src/components/Card';
import HeaderSearchInfo from 'src/components/Layout/Header/HeaderSearch//HeaderSearchInfo';
import HeaderSearchEmpty from 'src/components/Layout/Header/HeaderSearch/HeaderSearchEmpty';
import HeaderSearchListItem from 'src/components/Layout/Header/HeaderSearch/HeaderSearchListItem';
import { SEARCH_MIN_CHAR } from 'src/constants/search';
import { SearchModule, SearchModuleTabs } from 'src/interface';
import { removeEmptyParams } from 'src/utils/func';

import classes from './HeaderSearchResults.module.less';

type Props = {
	isComponentVisible: boolean;
	query: string;
	module: SearchModule;
	records: any;
	activeTab: string;
	setActiveTab: (v: string) => void;
	handleClickItemResult?: () => void;
};

const HeaderSearchResults = (props: Props) => {
	const {
		isComponentVisible,
		query,
		module,
		activeTab,
		records,
		setActiveTab,
		handleClickItemResult,
	} = props;
	const r = useRouter();
	const showList = query.length >= SEARCH_MIN_CHAR.FOR_LIST;

	return (
		<>
			{isComponentVisible && (
				<CardC className={classes.card}>
					{showList ? (
						<Tabs
							activeKey={activeTab}
							onTabClick={(key: string) => {
								setActiveTab(key);
							}}
							centered
						>
							{module.tabs.map((tab: SearchModuleTabs) => (
								<Tabs.TabPane key={tab.name} tab={tab.name} forceRender>
									<ConfigProvider renderEmpty={() => <HeaderSearchEmpty />}>
										<List
											className={classes.list}
											dataSource={tab.getData?.(records) || []}
											loading={records?.isLoading || records?.isPreviousData}
											renderItem={(item: Array<any>) => (
												<Link
													href={{
														pathname: tab.getUrl?.(item)?.pathname || '',
														query: removeEmptyParams({
															...tab.getUrl?.(item)?.query,
															voucher: r.query.voucher,
														}),
													}}
													onClick={handleClickItemResult}
												>
													<HeaderSearchListItem label={tab.getLabel?.({ ...item, query })} />
												</Link>
											)}
										/>
									</ConfigProvider>
								</Tabs.TabPane>
							))}
						</Tabs>
					) : (
						<HeaderSearchInfo />
					)}
				</CardC>
			)}
		</>
	);
};

export default HeaderSearchResults;
