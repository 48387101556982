import { useEffect, useRef, useState } from 'react';
import type { RootStateOrAny } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';

import { actionSetQuickAuthDisplay } from 'src/redux/actions/klikstore/quickauth';
import authStorage from 'src/utils/auth-storage';

const useAthorizedAction = () => {
	const isLogin = authStorage.loggedIn;
	const { klikstoreQuickAuthDisplay } = useSelector((state: RootStateOrAny) => state);
	const dispatch = useDispatch();
	const [running, setRunning] = useState<boolean>(false);
	const actionRef: any = useRef(null);

	const callAuthenticatedAction = (action: (...args: any[]) => void) => {
		actionRef.current = action;
		setRunning(true);
	};

	const triggerTargetFunction = async () => {
		if (actionRef?.current) {
			await actionRef.current();
		}
		actionRef.current = null;
		setRunning(false);
	};

	useEffect(() => {
		if (running) {
			if (!isLogin) {
				dispatch(
					actionSetQuickAuthDisplay({
						status: true,
					}),
				);
			} else {
				triggerTargetFunction();
			}
		}
	}, [isLogin, running]);

	useEffect(() => {
		if (!klikstoreQuickAuthDisplay?.klikstoreQuickAuthDisplayOpen) {
			setRunning(false);
		}
	}, [klikstoreQuickAuthDisplay]);

	return callAuthenticatedAction;
};

export default useAthorizedAction;
