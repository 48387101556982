import Highlighter from 'react-highlight-words';
import Link from 'next/link';
import { Col, ConfigProvider, Image, List, Row, Typography } from 'antd';
import classNames from 'classnames';

import CardC from 'src/components/Card';
import ImageC from 'src/components/Image';
import HeaderSearchEmpty from 'src/components/Layout/Header/HeaderSearch/HeaderSearchEmpty';
import useAthorizedAction from 'src/hooks/KlikStore/useAthorizedAction';
import { IKlikStoreMasterProduct, SearchModule } from 'src/interface';
import { getDefaultProductImg } from 'src/utils/func';

import SpinC from '../../Spin';
import HeaderSearchInfo from './HeaderSearchInfo';

import classes from './KlikstoreSearchResult.module.less';
type Props = {
	isLoading?: boolean;
	isComponentVisible: boolean;
	query: string;
	module: SearchModule;
	records: any;
	onKlikNowProductSelect: (item: IKlikStoreMasterProduct) => void;
};

const { Text } = Typography;

const KlikstoreSearchResult = (props: Props) => {
	const callAuthenticatedAction = useAthorizedAction();
	const { isLoading = false, isComponentVisible, records, query, onKlikNowProductSelect } = props;
	const showList = query.length >= 3;

	const keywords =
		query
			?.trim()
			?.split(' ')
			?.map((word) => word.replace(/[\-\[\]{}()*+?.,\\\^$%#\s]/g, '\\$&')) ?? [];

	const dataSourceProductsKlikNow = props.module.tab?.getData?.(records, 1) || [];
	const dataSourceProducts = props.module.tab?.getData?.(records, 2) || [];
	const dataSourceMerchants = props.module.tab?.getData(records, 3) || [];

	const handleKlikNowProductClick = (product: IKlikStoreMasterProduct) => {
		callAuthenticatedAction(() => onKlikNowProductSelect(product));
	};

	const isDataExist: boolean =
		(dataSourceProductsKlikNow && dataSourceProductsKlikNow.length > 0) ||
		(dataSourceProducts && dataSourceProducts.length > 0) ||
		(dataSourceMerchants && dataSourceMerchants.length > 0);

	return (
		<>
			{isComponentVisible && (
				<CardC className={classes.card}>
					{showList ? (
						<SpinC spinning={isLoading}>
							{!isLoading && !isDataExist ? (
								<HeaderSearchEmpty />
							) : (
								<Row gutter={[0, 18]}>
									{dataSourceProductsKlikNow?.length > 0 && (
										<Col span={24}>
											<div
												className={classNames(
													'd-flex align-items-center',
													classes['kliknow-header'],
												)}
											>
												<Image
													alt="Icon KlikNow"
													preview={false}
													src="/images/klikstore/kliknow.svg"
													height={16}
												/>
												<div>
													<div className={classNames('fw-600 fs-16 mb-16 ml-3', classes.title)}>
														Klik NOW
													</div>
													<div className={classNames('fw-600 fs-12 mb-12 ml-3', classes.desc)}>
														Belanja 2 Jam tiba & GRATIS ongkir!
													</div>
												</div>
											</div>
											<ConfigProvider renderEmpty={() => <HeaderSearchEmpty />}>
												<List
													className={classes.list}
													dataSource={dataSourceProductsKlikNow}
													renderItem={(item: IKlikStoreMasterProduct) => {
														return (
															<div onClick={() => handleKlikNowProductClick(item)}>
																<List.Item
																	className={classNames(
																		classes['wrapper-product'],
																		'fs-12 fs-sm-14 fs-md-16',
																	)}
																>
																	<div className="d-flex align-items-center">
																		<div className={classes.image}>
																			<ImageC
																				src={item.image}
																				defaultSrc={getDefaultProductImg()}
																				width={40}
																				height={40}
																				layout="responsive"
																				objectFit="contain"
																			/>
																		</div>
																		<Highlighter
																			className="ml-3"
																			searchWords={keywords}
																			textToHighlight={item.name}
																			highlightTag="strong"
																		/>
																	</div>
																</List.Item>
															</div>
														);
													}}
												/>
											</ConfigProvider>
										</Col>
									)}
									{dataSourceProducts?.length > 0 && (
										<Col span={24}>
											<Text className="fw-600 fs-16">Produk</Text>
											<ConfigProvider renderEmpty={() => <HeaderSearchEmpty />}>
												<List
													className={classes.list}
													dataSource={dataSourceProducts}
													renderItem={(item: IKlikStoreMasterProduct) => {
														return (
															<Link href={props.module.tab?.getUrl?.(item)}>
																<List.Item
																	className={classNames(
																		classes['wrapper-product'],
																		'fs-12 fs-sm-14 fs-md-16',
																	)}
																>
																	<div className="d-flex align-items-center">
																		<div className={classes.image}>
																			<ImageC
																				src={item.image}
																				defaultSrc={getDefaultProductImg()}
																				width={40}
																				height={40}
																				layout="responsive"
																				objectFit="contain"
																			/>
																		</div>
																		<Highlighter
																			className="ml-3"
																			searchWords={keywords}
																			textToHighlight={item.name}
																			highlightTag="strong"
																		/>
																	</div>
																</List.Item>
															</Link>
														);
													}}
												/>
											</ConfigProvider>
										</Col>
									)}
									{dataSourceMerchants?.length > 0 && (
										<Col span={24}>
											<Text className="fw-600 fs-16 mt-2 d-block">Toko</Text>
											<ConfigProvider renderEmpty={() => <HeaderSearchEmpty />}>
												<List
													className={classes.list}
													dataSource={dataSourceMerchants}
													renderItem={(item: any) => (
														<Link href={props.module.tab?.getUrl?.(item, 2)}>
															<List.Item
																className={classNames(
																	classes['wrapper-store'],
																	'fs-12 fs-sm-14 fs-md-16',
																)}
															>
																<div className="d-flex align-items-center">
																	<div className={classes.image}>
																		<ImageC
																			src={item.image}
																			defaultSrc={'/images/empty/store.png'}
																			width={40}
																			height={40}
																			layout="responsive"
																			objectFit="contain"
																		/>
																	</div>
																	<div className="ml-3">
																		<Highlighter
																			searchWords={keywords}
																			textToHighlight={item.name}
																			highlightTag="strong"
																		/>
																		<div>
																			<Text className="fs-12 text-neutral-5">{item.city}</Text>
																		</div>
																	</div>
																</div>
															</List.Item>
														</Link>
													)}
												/>
											</ConfigProvider>
										</Col>
									)}
								</Row>
							)}
						</SpinC>
					) : (
						<HeaderSearchInfo />
					)}
				</CardC>
			)}
		</>
	);
};

export default KlikstoreSearchResult;
