import Link from 'next/link';
import classNames from 'classnames';

import ImageC from 'src/components/Image';
import { KlikStoreCartProduct, KlikStoreCartStore } from 'src/interface';
import { cleanSlug } from 'src/utils/func/klikstore';

import Product from './Product';

import classes from './index.module.less';

interface StoresProductsProps {
	isLoading: boolean;
	allProducts: KlikStoreCartStore[];
	totalProducts: number;
}

const StoresProducts = ({ allProducts }: StoresProductsProps) => {
	return (
		<div className={classes['stores-products']}>
			{allProducts.map((store: KlikStoreCartStore) => {
				const isMerchantActive = (store.status ?? 1) === 1;

				const allProductsOfStore: KlikStoreCartProduct[] = store?.orderQuoteItems ?? [];

				if (allProductsOfStore.length === 0) {
					return null;
				}

				return (
					<div key={store.merchant_id} className={classes.store}>
						<Link href={cleanSlug(`/kalstore/s/${store?.slug ?? ''}`)} style={{ color: 'unset' }}>
							<div
								className={classNames(classes['store-name'], {
									[classes.disabled]: !isMerchantActive,
								})}
							>
								<div className={classes.image}>
									<ImageC
										src={store.image}
										defaultSrc="/images/empty/store.png"
										alt={store.name}
										width={32}
										height={32}
									/>
								</div>
								<div className={classes.name}>
									<div className="fs-12">{store.name}</div>
									{isMerchantActive ? (
										<span className={classNames(classes.location, 'fs-10')}>{store.city}</span>
									) : (
										<span className={classNames(classes['store-status'], 'fs-10')}>
											Toko Tidak Aktif
										</span>
									)}
								</div>
							</div>
						</Link>
						<div className={classes.products}>
							{allProductsOfStore?.map((product: KlikStoreCartProduct) => (
								<Product
									key={store.merchant_id + '-' + product.id}
									store={store}
									product={product}
								/>
							))}
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default StoresProducts;
