import { RootStateOrAny, useSelector } from 'react-redux';
import { Drawer, Grid, Space, Typography } from 'antd';

import ImageC from 'src/components/Image';
import LogoC from 'src/components/Layout/Header/Logo';

import classes from './DrawerCart.module.less';

const DrawerCart = (props: any) => {
	const { homepage } = useSelector((state: RootStateOrAny) => state);
	const dataDownload = homepage.download?.data?.record || {};
	const { xs } = Grid.useBreakpoint();

	return (
		<>
			<Drawer
				width={xs ? 262 : 385}
				height={'100%'}
				className={`${!props.visibleDrawer ? '' : classes['drawer-open']} ${
					props.classesDrawers
				} foobar`}
				placement={props.placement ? props.placement : 'top'}
				closable={false}
				visible={props.visibleDrawer}
				bodyStyle={{ overflowX: 'hidden' }}
				zIndex={10}
				onClose={props.setVisibleDrawerClose}
			>
				{props.showingHeader && (
					<div className={classes['header-drawer']}>
						<Space className={classes['space-title']}>
							{props.hiddenBackIcon ? (
								<>
									<ImageC
										alt="Back"
										height={24}
										width={24}
										src="/icons/arrow-left.svg"
										onClick={props.setVisibleDrawerClose}
									/>
								</>
							) : (
								''
							)}
							<Typography.Text className={classes['title-drawer']}>
								{props.textHeader}
							</Typography.Text>
						</Space>
					</div>
				)}
				{props.showingLogo && (
					<div className={classes['drawer-logo']}>
						<LogoC />
					</div>
				)}
				<div className={classes['drawer-body']}>
					{props.children}
					<div className={classes['drawer-footer']}>
						<p>Download aplikasi KlikDokter di</p>
						<div className={classes['download-area']}>
							<a href={dataDownload?.download_gplay_link_url} target="_blank" rel="noreferrer">
								<ImageC src={dataDownload?.download_gplay_icon_url} height={50} />
							</a>
							<a href={dataDownload?.download_appstore_link_url} target="_blank" rel="noreferrer">
								<ImageC src={dataDownload?.download_appstore_icon_url} height={50} />
							</a>
						</div>
					</div>
				</div>
			</Drawer>
		</>
	);
};

export default DrawerCart;
