import { Grid } from 'antd';

import EmptyC from 'src/components/Layout/Empty';

import classes from './HeaderSearchEmpty.module.less';

const HeaderSearchEmpty = () => {
	const { xs } = Grid.useBreakpoint();

	return (
		<EmptyC
			className={classes.empty}
			image="/images/empty/search.svg"
			imageWidth={xs ? 130 : 160}
			imageHeight={xs ? 130 : 160}
			title="Pencarian tidak ditemukan"
			description="Sepertinya yang kamu cari tidak ada, coba kata kunci lain."
			customHeaderEmpty={classes.header}
			customParagraphEmpty={classes.paragraph}
			buttonText=""
			buttonHidden={true}
		/>
	);
};

export default HeaderSearchEmpty;
