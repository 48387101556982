import { Badge, Row, Typography } from 'antd';

import ImageC from 'src/components/Image';

import classes from '../UserMenu/UserNotificationMenu.module.less';
const WaitingPaymentNotification = (props: any) => {
	return (
		<>
			<div
				onClick={props.DirectLink}
				className={`${classes['card-waiting']} mb-0 mt-3 border-gray bg-gray rounded-sm`}
			>
				<Row justify="space-between">
					<div>
						<Typography.Text className={`${classes['text-header-waiting']} fw-600`}>
							{props.textInfo}
						</Typography.Text>
					</div>

					<Row>
						<Badge className={`${classes.badge} mr-3`} count={props.count} />
						<div className="mt-2px">
							<ImageC
								src="/icons/arrow-chevron-right.svg"
								height={16}
								width={16}
								alt="arrow-chevron-right"
							/>
						</div>
					</Row>
				</Row>
			</div>
		</>
	);
};

export default WaitingPaymentNotification;
