import { MENU_TYPE } from 'src/constants/redux';
import { LOADER_TYPE } from 'src/constants/redux/loader';
import { MODIFY_DATA, RESET_DATA, SINGLE_API, STORE_DATA } from 'src/redux/actions/types';
import {
	fetchApiMarketplace,
	fetchApiPublishing,
	fetchApiTypesense,
	fetchApiV3Publishing,
	fetchApiV3User,
} from 'src/utils/fetch-api';
import { getCategoryListFromCategoryTree } from 'src/utils/func/klikstore';

const singleApi = async (
	dataApi: any,
	dispatch: (data: { type: string; payload?: any }) => void,
) => {
	const {
		url,
		options,
		payload = {},
		notif,
		beforeCallType,
		successType,
		errorType,
		apiType = '',
		next = (...f: any) => f,
	} = dataApi;

	try {
		dispatch({ type: LOADER_TYPE.model.loading.start });
		if (beforeCallType) dispatch({ type: beforeCallType });

		let response = {};
		let remapCategory: { [key: string]: any[] } = { categories: [] };

		if (apiType === 'v3_pb') {
			response = await fetchApiV3Publishing({
				url,
				options,
				payload,
				notif,
			});
		} else if (apiType === 'v3_user') {
			response = await fetchApiV3User({
				url,
				options,
				payload,
				notif,
			});
		} else if (apiType === 'typesense') {
			response = await fetchApiTypesense({
				url,
				options,
				payload,
				notif,
			});
		} else if (apiType === 'marketplace') {
			response = await fetchApiMarketplace({
				url,
				options,
				payload,
				notif,
			});
		} else {
			response = await fetchApiPublishing({
				url,
				options,
				payload,
				notif,
			});
		}

		if (successType === MENU_TYPE.model.main.success) {
			let res = await fetchApiMarketplace({
				url: '/search/categoryTree',
				options: { method: 'GET' },
			});
			if (res?.data?.records?.length > 0) {
				remapCategory.categories = getCategoryListFromCategoryTree(res?.data?.records);
			}
		}
		next(null, response);

		if (successType) dispatch({ type: successType, payload: { ...response, ...remapCategory } });
		dispatch({ type: LOADER_TYPE.model.loading.stop });

		return response;
	} catch (error) {
		next(error);

		if (errorType) dispatch({ type: errorType, payload: error });
		dispatch({ type: LOADER_TYPE.model.loading.stop });

		throw error;
	}
};

const modifyData = async (
	modifyDataApi: any,
	dispatch: (data: { type: string; payload?: any }) => void,
) => {
	const {
		payload = {},
		beforeCallType,
		successType,
		errorType,
		next = (...f: any) => f,
	} = modifyDataApi;

	try {
		dispatch({ type: LOADER_TYPE.model.loading.start });
		if (beforeCallType) dispatch({ type: beforeCallType });

		next(null, null);

		if (successType) dispatch({ type: successType, payload: payload });
		dispatch({ type: LOADER_TYPE.model.loading.stop });

		return null;
	} catch (error) {
		next(error);

		if (errorType) dispatch({ type: errorType, payload: error });
		dispatch({ type: LOADER_TYPE.model.loading.stop });

		throw error;
	}
};

const resetData = async (
	resetDataApi: any,
	dispatch: (data: { type: string; payload?: any }) => void,
) => {
	const { beforeCallType, successType, errorType, next = (...f: any) => f } = resetDataApi;

	try {
		dispatch({ type: LOADER_TYPE.model.loading.start });
		if (beforeCallType) dispatch({ type: beforeCallType });

		next(null, null);

		if (successType) dispatch({ type: successType });
		dispatch({ type: LOADER_TYPE.model.loading.stop });

		return null;
	} catch (error) {
		next(error);

		if (errorType) dispatch({ type: errorType, payload: error });
		dispatch({ type: LOADER_TYPE.model.loading.stop });

		throw error;
	}
};

const storeData = async (
	singleStoreApi: any,
	dispatch: (data: { type: string; payload?: any }) => void,
) => {
	const {
		payload = {},
		beforeCallType,
		successType,
		errorType,
		next = (...f: any) => f,
	} = singleStoreApi;

	try {
		dispatch({ type: LOADER_TYPE.model.loading.start });
		if (beforeCallType) dispatch({ type: beforeCallType });

		next(null, null);

		if (successType) dispatch({ type: successType, payload: payload });
		dispatch({ type: LOADER_TYPE.model.loading.stop });
	} catch (error) {
		next(error);

		if (errorType) dispatch({ type: errorType, payload: error });
		dispatch({ type: LOADER_TYPE.model.loading.stop });

		throw error;
	}
};

const middleware =
	({ dispatch }: { dispatch: any }) =>
	(next: (...f: any) => void) =>
	(action: any) => {
		switch (action.type) {
			case SINGLE_API:
				return singleApi(action.payload, dispatch);
			case MODIFY_DATA:
				return modifyData(action.payload, dispatch);
			case RESET_DATA:
				return resetData(action.payload, dispatch);
			case STORE_DATA:
				return storeData(action.payload, dispatch);
			default:
				return next(action);
		}
	};

export default middleware;
