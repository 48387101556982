import { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Badge, Drawer, Dropdown, Grid, Menu, Typography } from 'antd';
import classNames from 'classnames';

import Image from 'src/components/Image';
import EmptyC from 'src/components/Layout/Empty';
import SpinC from 'src/components/Layout/Spin';
import { useKlikStoreGetCart } from 'src/hooks/KlikStore/Payment';
import { KlikStoreCart, KlikStoreCartProduct, KlikStoreCartStore } from 'src/interface';
import { trackInsiderObject } from 'src/utils/analytics/insider';
import { textAmount } from 'src/utils/func';
import useOnClickOutside from 'src/utils/hook/useOnClickOutside';

import DrawerCart from './DrawerCart';
import StoresProducts from './StoresProducts';

import classes from './index.module.less';

const UserCartMenuItem = ({
	isLoading,
	allProducts,
	totalPrices,
	totalProducts,
	...props
}: any) => {
	const activeKeyTab = '1';

	const ConstGetProducts = () => {
		return !isLoading && totalProducts === 0 ? (
			<EmptyC
				className="wrapper-empty-state"
				customHeaderEmpty={`${classes['header-empty']}`}
				customParagraphEmpty={classes['paragraph-empty']}
				image="/images/empty/cart.svg"
				title="Keranjang Masih Kosong"
				description=""
				buttonText=""
				imageWidth={160}
				imageHeight={160}
				buttonHidden={true}
			/>
		) : (
			<SpinC spinning={isLoading}>
				{totalProducts > 0 && (
					<InfiniteScroll
						dataLength={totalProducts ?? 0}
						hasMore={false}
						next={() => {}}
						loader={<SpinC />}
						scrollableTarget={'scrollableDiv'}
					>
						<StoresProducts
							isLoading={false}
							allProducts={allProducts}
							totalProducts={totalProducts}
						/>
					</InfiniteScroll>
				)}
			</SpinC>
		);
	};

	return (
		<Menu className={classNames('user-notification-drawer-menu')}>
			{props.itemTitle && (
				<Typography.Text className={classes['title-drawer-notification']}>
					{props.itemTitle} <span className={classes['total-products']}>({totalProducts})</span>
				</Typography.Text>
			)}
			<div
				className={classNames(classes['wrapper-menu-list'], {
					[classes['wrap-menu-transaction']]: activeKeyTab === '1',
				})}
				id="scrollableDiv"
			>
				{ConstGetProducts()}
			</div>
			{totalProducts && totalProducts > 0 && (
				<Typography.Text className={classes.footer}>
					<Typography.Text>
						Total: <span className="fw-600">{textAmount(totalPrices)}</span>
					</Typography.Text>
					<Link className="fw-600" href={'/cart'} onClick={() => props.setVisible('')}>
						Lihat Keranjang
					</Link>
				</Typography.Text>
			)}
		</Menu>
	);
};

const UserCartMenu = (props: any) => {
	const router = useRouter();
	const isCheckoutPage = router.pathname.includes('/checkout/');

	const { xs } = Grid.useBreakpoint();

	const classesDrawers = xs
		? classes['user-notification-drawer-mobile']
		: classes['user-notification-drawer-desktop'];
	const hiddenBackIcon = xs ? true : false;

	const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
	const [currentTop, setCurrentTop] = useState<number>(0);
	const iconSize = xs ? 20 : 24;
	const menuRef = useRef<HTMLDivElement>(null);
	const { data, isLoading } = useKlikStoreGetCart({
		enabled: router.isReady,
	});
	const cartData: KlikStoreCart = data?.data?.record;
	const allProducts: KlikStoreCartStore[] = cartData?.orderQuoteMerchant ?? [];

	let productList: KlikStoreCartProduct[] = [];
	let totalPrices = 0;
	let totalProducts = 0;

	allProducts?.forEach(({ name, slug, orderQuoteItems, ...store }: KlikStoreCartStore) => {
		productList = [
			...productList,
			...(orderQuoteItems?.map((item: KlikStoreCartProduct) => ({
				...item,
				merchant_name: name,
				merchant_slug: slug,
			})) ?? []),
		];

		orderQuoteItems?.forEach(({ price, quantity, stock, ...product }: KlikStoreCartProduct) => {
			totalProducts += 1;

			if (
				(((store.status ?? 1) === 1 && product.status) ?? 1) === 1 &&
				(product.is_active ?? 1) === 1 &&
				(product.merchant_product_status ?? 1) === 1 &&
				parseInt(stock) > 0
			) {
				const finalPrice = parseInt(price);
				const qty = parseInt(quantity);

				totalPrices += qty * finalPrice;
			}
		});
	});

	const clickOutsidehandler = (event: any) => {
		if (props.visibleComponent === '' || props.visibleComponent === false) return;

		event.preventDefault();

		const btnEl: any = document.querySelector('#button-notification');
		const firstClass = event?.target?.className?.split?.(' ') ?? [];
		let containClass = '';
		if (firstClass.length > 0 && firstClass[0].length > 0) {
			containClass = `.${firstClass[0]}`;
		}

		// ignore notification button click trigger re open
		if (!(btnEl && containClass.length > 0 && btnEl?.querySelector(containClass))) {
			if (drawerOpen) {
				props.setVisibleComponent('');
			}
		}
	};

	useOnClickOutside(menuRef, clickOutsidehandler);

	useEffect(() => {
		const headerHeight = xs ? 120 : 85;
		const idealTop = headerHeight - document.documentElement.scrollTop;
		setCurrentTop(idealTop);
		setDrawerOpen(props.visibleComponent === 'shopping-cart');
	}, [props.visibleComponent, xs]);

	useEffect(() => {
		if (cartData && !isCheckoutPage) {
			trackInsiderObject('basket')({
				currency: 'IDR',
				total: totalPrices,
				line_items:
					productList?.map((product: any) => ({
						product: {
							id: product.product_merchant_uid,
							name: product.name,
							taxonomy: [],
							currency: 'IDR',
							unit_price: parseInt(product.original_price),
							unit_sale_price: parseInt(product.price),
							url: `${window.location.origin}/kalstore/s/${product.merchant_slug}/${product.slug}`,
							stock: parseInt(product.stock),
							color: '',
							size: `${parseInt(product.weight)} kg`,
							product_image_url: product.image,
						},
						quantity: parseInt(product.quantity),
						subtotal: parseInt(product.price) * parseInt(product.quantity),
					})) ?? [],
				shipping_cost: cartData.shipping_amount,
			});
		}
	}, [cartData]);

	return (
		<div className={classes['user-cart-menu']}>
			{xs ? (
				<>
					<div
						onClick={() =>
							props.visibleComponent != 'shopping-cart'
								? props.setVisibleComponent('shopping-cart')
								: props.setVisibleComponent('')
						}
					>
						<Badge className={classes.badge} offset={[-1, 1]} count={totalProducts}>
							<Image
								width={iconSize}
								height={iconSize}
								alt="Shopping Cart"
								src="/icons/shopping-cart.svg"
								layout="fixed"
							/>
						</Badge>
					</div>

					<>
						<DrawerCart
							classesDrawers={classesDrawers}
							textHeader="Keranjang"
							placement={'top'}
							showingHeader={true}
							showingLogo={false}
							visibleDrawer={props.visibleComponent == 'shopping-cart'}
							paddingTopDrawer={currentTop}
							setVisibleDrawerClose={() => props.setVisibleComponent('')}
							hiddenBackIcon={hiddenBackIcon}
						>
							<UserCartMenuItem
								setVisible={props.setVisibleComponent}
								isLoading={isLoading}
								allProducts={allProducts}
								totalPrices={totalPrices}
								totalProducts={totalProducts}
							/>
						</DrawerCart>
					</>
				</>
			) : (
				<Dropdown
					overlayClassName={classes['user-message-dropdown']}
					overlay={
						<div className={classes['overlay-desktop-notification']} ref={menuRef}>
							<UserCartMenuItem
								setVisible={props.setVisibleComponent}
								itemTitle="Keranjang"
								isLoading={isLoading}
								allProducts={allProducts}
								totalPrices={totalPrices}
								totalProducts={totalProducts}
							/>
						</div>
					}
					trigger={['click']}
					placement="bottomRight"
					visible={props.visibleComponent == 'shopping-cart'}
					disabled
				>
					<div
						id={'button-notification'}
						onClick={(e) => {
							e.stopPropagation();
							return props.visibleComponent != 'shopping-cart'
								? props.setVisibleComponent('shopping-cart')
								: props.setVisibleComponent('');
						}}
					>
						<Badge className={classes.badge} offset={[-1, 1]} count={totalProducts}>
							<Image
								width={iconSize}
								height={iconSize}
								className="icon-shopping-cart"
								alt="Shopping Cart"
								src="/icons/shopping-cart.svg"
								layout="fixed"
							/>
						</Badge>
					</div>
				</Dropdown>
			)}
			<Drawer visible={drawerOpen} className={classes['backdrop-drawer']} placement="top">
				<div className={classes['backdrop-overlay']} />
			</Drawer>
		</div>
	);
};

export default UserCartMenu;
