import { Fragment, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Router from 'next/router';
import { Badge, Col, Drawer, Dropdown, Grid, Menu, Row, Typography } from 'antd';
import classNames from 'classnames';

import Image from 'src/components/Image';
import global from 'src/constants/global';
import g from 'src/constants/urls';
import {
	UpdateNotificationRead,
	useNotificationInfo,
	useNotificationPromo,
	useNotificationTransaction,
} from 'src/hooks/Notification';
import useTotalNotificationCount from 'src/hooks/useTotalNotificationCount';
import { useCountWaiting } from 'src/hooks/Waiting';
import useOnClickOutside from 'src/utils/hook/useOnClickOutside';

import EmptyC from '../../Empty';
import SpinC from '../../Spin';
import DrawerNotification from '../TabMenuNotification/DrawerNotification';
import HeaderNotification from '../TabMenuNotification/HeaderNotification';
import InfoMenu from '../TabMenuNotification/Menu/InfoMenu';
import PromoMenu from '../TabMenuNotification/Menu/PromoMenu';
import TransactionMenu from '../TabMenuNotification/Menu/TransactionMenu';
import TabMenuNotification from '../TabMenuNotification/TabMenuNotification';
import WaitingPaymentNotification from '../TabMenuNotification/WaitingPaymentNotification';

const { prescription, MembershipReminderExpired, MembershipBirthday } = global.NOTIFICATION.INFO;

import { usePaymentExcess } from 'src/hooks';

import classes from './UserNotificationMenu.module.less';

const Tab = [
	{ id: 1, name: 'Transaksi', key: '1' },
	{ id: 2, name: 'Promo', key: '2' },
	{ id: 3, name: 'Info', key: '3' },
];

const UserNotificationMenuItem = (props: any) => {
	const [activeKeyTab, setActiveKeyTab] = useState<string>('1');
	const { setVisible } = props;
	const ClickDirectLink = (link: string) => {
		setVisible(false);
		Router.push(link);
	};

	const [loadMoreTransaction, setLoadMoreTransaction] = useState({
		number: 1,
		loading: false,
	});
	const [loadMorePromo, setLoadMorePromo] = useState({
		number: 1,
		loading: false,
	});
	const [loadMoreInfo, setLoadMoreInfo] = useState({
		number: 1,
		loading: false,
	});

	const notificationInfo = useNotificationInfo(loadMoreInfo.number, activeKeyTab);
	const notificationPromo = useNotificationPromo(loadMorePromo.number, activeKeyTab);
	const notificationTransaction = useNotificationTransaction(
		loadMoreTransaction.number,
		activeKeyTab,
	);

	const [arrayTransaction, setArrayTransaction] = useState([]);
	const [arrayPromo, setArrayPromo] = useState([]);
	const [arrayInfo, setArrayInfo] = useState([]);

	const { mutate: update } = UpdateNotificationRead();
	const waitingCountTransaction = useCountWaiting();

	const { checkPaymentExcess } = usePaymentExcess();

	const submitButtonUpdate = (id: any, link?: any, query?: Record<string, any>) => {
		update(id, {
			onSuccess: () => {
				setVisible(false);
				if (link) {
					Router.push({
						pathname: link,
						query,
					});
				}
			},
		});
	};

	useEffect(() => {
		if (loadMoreTransaction.number == 1) {
			setArrayTransaction(notificationTransaction?.data?.data?.records);
		} else {
			const timer =
				loadMoreTransaction.number > 1 &&
				loadMoreTransaction.loading &&
				setInterval(() => {
					setArrayTransaction(
						arrayTransaction.concat(notificationTransaction?.data?.data?.records),
					);
					setLoadMoreTransaction({
						...loadMoreTransaction,
						loading: false,
					});
				}, 1000);
			return () => clearInterval(timer as NodeJS.Timeout);
		}
	}, [notificationTransaction, arrayTransaction, loadMoreTransaction]);

	useEffect(() => {
		if (loadMorePromo.number == 1) {
			setArrayPromo(notificationPromo?.data?.data?.records);
		} else {
			const timer =
				loadMorePromo.number > 1 &&
				loadMorePromo.loading &&
				setInterval(() => {
					setArrayPromo(arrayPromo.concat(notificationPromo?.data?.data?.records));
					setLoadMorePromo({
						...loadMorePromo,
						loading: false,
					});
				}, 1000);
			return () => clearInterval(timer as NodeJS.Timeout);
		}
	}, [notificationPromo, arrayPromo, loadMorePromo]);

	useEffect(() => {
		if (loadMoreInfo.number == 1) {
			setArrayInfo(notificationInfo?.data?.data?.records);
		} else {
			const timer =
				loadMoreInfo.number > 1 &&
				loadMoreInfo.loading &&
				setInterval(() => {
					setArrayInfo(arrayInfo.concat(notificationInfo?.data?.data?.records));
					setLoadMoreInfo({
						...loadMoreInfo,
						loading: false,
					});
				}, 1000);
			return () => clearInterval(timer as NodeJS.Timeout);
		}
	}, [notificationInfo, arrayInfo, loadMoreInfo]);
	const ConstWaitingCondition = waitingCountTransaction.data
		? waitingCountTransaction?.data?.data?.waiting
		: 0;

	const ConstHeaderNotification = () => {
		if (activeKeyTab == '1') {
			return (
				<>
					<WaitingPaymentNotification
						count={ConstWaitingCondition}
						textInfo="Menunggu Pembayaran"
						DirectLink={() => ClickDirectLink('/profil/menunggu-pembayaran')}
					/>
					<HeaderNotification
						HeaderText="Daftar Transaksi"
						DirectLink={() => ClickDirectLink('/profil/daftar-transaksi')}
						HiddenDirectLink={notificationTransaction?.data?.data?.records?.length === 0}
					/>
				</>
			);
		} else if (activeKeyTab == '2') {
			return (
				<HeaderNotification
					HeaderText="Promo"
					DirectLink={() => ClickDirectLink('/promo')}
					HiddenDirectLink={notificationPromo.data?.data?.records?.length === 0}
				/>
			);
		} else {
			return (
				<HeaderNotification HeaderText="Info Terkini" DirectLink={''} HiddenDirectLink={true} />
			);
		}
	};

	const ConstGetNotifications = () => {
		if (activeKeyTab == '1') {
			{
				return notificationTransaction?.data?.data?.records?.length == 0 ? (
					<EmptyC
						className="wrapper-empty-state"
						customHeaderEmpty={`${classes['header-empty']}`}
						customParagraphEmpty={classes['paragraph-empty']}
						image="/icons/notifikasi/ic_empty_notifikasi.svg"
						title="Belum Ada Transaksi"
						description={
							'Kamu belum punya transaksi baru, lakukan transaksi langsung di KlikDokter yah.'
						}
						buttonText=""
						imageWidth={160}
						imageHeight={160}
						buttonHidden={true}
					/>
				) : (
					<InfiniteScroll
						dataLength={notificationTransaction?.data?.data?.records?.length || null}
						hasMore={
							notificationTransaction?.data?.meta?.pagination?.page <
							notificationTransaction?.data?.meta?.pagination?.totalpage
						}
						next={() => {
							setTimeout(() => {
								setLoadMoreTransaction({
									number: loadMoreTransaction.number + 1,
									loading: true,
								});
							}, 1000);
						}}
						loader={<SpinC />}
						scrollableTarget={'scrollableDiv'}
					>
						{arrayTransaction?.map((menu: any, index: any) => {
							return (
								<Fragment key={index}>
									<TransactionMenu
										className={`row-notification-${menu.id}`}
										onClick={() => {
											const el = document.getElementsByClassName(`row-notification-${menu.id}`);
											if (el && el[0]) {
												const target: any = el[0];
												target.classList.add(classes['removed-bg']);
											}

											let tLink: string = `/profil/daftar-transaksi/detail/${menu.invoice_id}`;
											if (menu?.module === 'Belanja Sehat') {
												tLink = `/profil/daftar-transaksi/detail/ks-${menu.order_id}`;
											}

											const navigate = (link?: string, query?: Record<string, any>) => {
												submitButtonUpdate(menu.id, link, query);
											};

											if (!menu?.is_payment_excess) {
												navigate(tLink);
											} else {
												checkPaymentExcess({
													invoiceId: menu.invoice_id,
													module: menu.module,
													onSuccess: navigate,
													onFailed: () => {
														navigate(tLink);
													},
												});
											}
											return true;
										}}
										transaction_data={menu}
									/>
									<Menu.Divider className={classes.divider} />
								</Fragment>
							);
						})}
					</InfiniteScroll>
				);
			}
		} else if (activeKeyTab == '2') {
			return notificationPromo.data?.data?.records?.length === 0 ? (
				<EmptyC
					className="wrapper-empty-state"
					customHeaderEmpty={`${classes['header-empty']}`}
					customParagraphEmpty={classes['paragraph-empty']}
					image="/icons/notifikasi/ic_empty_notifikasi.svg"
					title="Belum Ada Promo"
					description={
						'Promo baru belum tersedia. Tunggu kejutan dan promo menarik dari KlikDokter.'
					}
					buttonText=""
					imageWidth={160}
					imageHeight={160}
					buttonHidden={true}
				/>
			) : (
				<InfiniteScroll
					dataLength={notificationPromo.data?.data?.records?.length || null}
					next={() => {
						setTimeout(() => {
							setLoadMorePromo({
								number: loadMorePromo.number + 1,
								loading: true,
							});
						}, 1000);
					}}
					hasMore={
						notificationPromo.data?.meta?.pagination?.page <
						notificationPromo.data?.meta?.pagination?.totalpage
					}
					loader={<SpinC />}
					scrollableTarget={'scrollableDiv'}
				>
					{arrayPromo?.map((menu: any, index: any) => {
						return (
							<Fragment key={index}>
								<PromoMenu
									onClick={() => {
										Router.push(`/promo/${menu.promo_id}`);
										setVisible(false);
									}}
									promo_data={menu}
								/>
								<Menu.Divider className={classes.divider} />
							</Fragment>
						);
					})}
				</InfiniteScroll>
			);
		} else {
			return notificationInfo.data?.data?.records?.length === 0 ? (
				<EmptyC
					className="wrapper-empty-state"
					customHeaderEmpty={`${classes['header-empty']}`}
					customParagraphEmpty={classes['paragraph-empty']}
					image="/icons/notifikasi/ic_empty_notifikasi.svg"
					title="Tidak Ada Info"
					description={'Kamu belum memiliki informasi terbaru dari KlikDokter'}
					buttonText=""
					imageWidth={160}
					imageHeight={160}
					buttonHidden={true}
				/>
			) : (
				<InfiniteScroll
					dataLength={notificationInfo.data?.data?.records?.length || null}
					next={() => {
						setTimeout(() => {
							setLoadMoreInfo({
								number: loadMoreInfo.number + 1,
								loading: true,
							});
						}, 1000);
					}}
					hasMore={
						notificationInfo.data?.meta?.pagination?.page <
						notificationInfo.data?.meta?.pagination?.totalpage
					}
					loader={<SpinC />}
					scrollableTarget={'scrollableDiv'}
				>
					{arrayInfo?.map((menu: any, index: any) => {
						return (
							<Fragment key={index}>
								<Fragment key={index}>
									<InfoMenu
										onClick={() => {
											if (menu.module === prescription) {
												submitButtonUpdate(menu.id, menu.deeplink);
											} else if (menu.module === MembershipReminderExpired) {
												Router.push(g.WEB_URL + '/profil/klikpoin?tab=redeem-voucher');
											} else if (menu.module === MembershipBirthday) {
												Router.push(g.WEB_URL + '/profil/klikpoin?tab=my-voucher');
											} else {
												submitButtonUpdate(menu.id, '/');
											}
										}}
										info_data={menu}
									/>
								</Fragment>
								<Menu.Divider className={classes.divider} />
							</Fragment>
						);
					})}
				</InfiniteScroll>
			);
		}
	};

	return (
		<Menu className={classNames('user-notification-drawer-menu')}>
			{props.itemTitle && (
				<Typography.Text className={classes['title-drawer-notification']}>
					{props.itemTitle}
				</Typography.Text>
			)}
			<div className="w-100 ml-auto mr-auto px-4 border-bottom-2px mt-2px">
				<Row justify="space-between" className={classes['rows-tab-notification']}>
					{Tab.map((tab) => {
						return (
							<Fragment key={tab.id}>
								<Col>
									<TabMenuNotification
										activeKey={activeKeyTab}
										nameOfTab={tab.name}
										keyOfTab={tab.id}
										OnChangeTab={() => setActiveKeyTab(tab.key)}
									/>
								</Col>
							</Fragment>
						);
					})}
				</Row>
			</div>
			{ConstHeaderNotification()}
			<div
				className={classNames(classes['wrapper-menu-list'], {
					[classes['wrap-menu-transaction']]: activeKeyTab === '1',
				})}
				id="scrollableDiv"
			>
				{ConstGetNotifications()}
			</div>
		</Menu>
	);
};

const UserNotificationMenu = (props: any) => {
	const { xs } = Grid.useBreakpoint();
	const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
	const [currentTop, setCurrentTop] = useState<number>(0);
	const iconSize = xs ? 20 : 24;
	const menuRef = useRef<HTMLDivElement>(null);
	const totalNotif = useTotalNotificationCount();

	const clickOutsidehandler = (event: any) => {
		if (props.visibleComponent === '' || props.visibleComponent === false) return;

		event.preventDefault();

		const btnEl: any = document.querySelector('#button-notification');
		const firstClass = event?.target?.className?.split?.(' ') ?? [];
		let containClass = '';
		if (firstClass.length > 0 && firstClass[0].length > 0) {
			containClass = `.${firstClass[0]}`;
		}

		// ignore notification button click trigger re open
		if (!(btnEl && containClass.length > 0 && btnEl?.querySelector(containClass))) {
			if (drawerOpen) {
				props.setVisibleComponent('');
			}
		}
	};

	useOnClickOutside(menuRef, clickOutsidehandler);

	useEffect(() => {
		const headerHeight = xs ? 120 : 85;
		const idealTop = headerHeight - document.documentElement.scrollTop;
		setCurrentTop(idealTop);
		setDrawerOpen(props.visibleComponent === 'notifikasi');
	}, [props.visibleComponent, xs]);

	return (
		<div className={classes['user-notification-menu']}>
			{xs ? (
				<>
					<div
						onClick={() =>
							props.visibleComponent != 'notifikasi'
								? props.setVisibleComponent('notifikasi')
								: props.setVisibleComponent('')
						}
					>
						<Badge className={classes.badge} offset={[-1, 1]} count={totalNotif.main}>
							<Image
								width={iconSize}
								height={iconSize}
								alt="Message"
								src="/icons/notification.svg"
								layout="fixed"
							/>
						</Badge>
					</div>

					<>
						<DrawerNotification
							classesDrawers={
								classes[xs ? 'user-notification-drawer-mobile' : 'user-notification-drawer-desktop']
							}
							textHeader="Notifikasi"
							placement={'top'}
							showingHeader={true}
							showingLogo={false}
							visibleDrawer={props.visibleComponent == 'notifikasi'}
							paddingTopDrawer={currentTop}
							setVisibleDrawerClose={() => props.setVisibleComponent('')}
							hiddenBackIcon={xs ? true : false}
						>
							<UserNotificationMenuItem setVisible={props.setVisibleComponent} />
						</DrawerNotification>
					</>
				</>
			) : (
				<Dropdown
					overlayClassName={classes['user-message-dropdown']}
					overlay={
						<div className={classes['overlay-desktop-notification']} ref={menuRef}>
							<UserNotificationMenuItem
								setVisible={props.setVisibleComponent}
								itemTitle="Notifikasi"
							/>
						</div>
					}
					trigger={['click']}
					placement="bottomRight"
					visible={props.visibleComponent == 'notifikasi'}
					disabled
				>
					<div
						id={'button-notification'}
						onClick={(e) => {
							e.stopPropagation();
							return props.visibleComponent != 'notifikasi'
								? props.setVisibleComponent('notifikasi')
								: props.setVisibleComponent('');
						}}
					>
						<Badge className={classes.badge} offset={[-1, 1]} count={totalNotif.main}>
							<Image
								width={iconSize}
								height={iconSize}
								className="icon-notification"
								alt="Message"
								src="/icons/notification.svg"
								layout="fixed"
							/>
						</Badge>
					</div>
				</Dropdown>
			)}
			<Drawer visible={drawerOpen} className={classes['backdrop-drawer']} placement="top">
				<div className={classes['backdrop-overlay']} />
			</Drawer>
		</div>
	);
};

export default UserNotificationMenu;
