import { Row, Typography } from 'antd';

import ImageC from 'src/components/Image';
import { getDefaultImg } from 'src/utils/func';

import WrapNotif from './WrapNotif';

import classes from '../../UserMenu/UserNotificationMenu.module.less';

const TransactionMenu = (props: any) => {
	return (
		<>
			<WrapNotif
				cardStatus={props.transaction_data.status == 0}
				onClickNotifiation={props.onClick}
				className={props.className}
			>
				<div className={'w-80'}>
					<Typography.Title
						className={classes['text-header']}
						style={{ color: `#${props.transaction_data.title_colour}` }}
					>
						{props.transaction_data.title}
					</Typography.Title>

					<Typography.Text className={classes['text-label-detail-purpose']}>
						{props.transaction_data.custom_message}
					</Typography.Text>

					<Row>
						<Typography.Text className={classes['text-label-purpose']}>
							{props.transaction_data.module === 'Belanja Sehat'
								? 'KALStore'
								: props.transaction_data.module}
						</Typography.Text>

						<div className={classes['dots-text']} />

						<Typography.Text className={classes['text-label-detail-purpose']}>
							{props.transaction_data.message}
						</Typography.Text>
					</Row>

					<Typography.Text className={classes['text-label']}>
						{props.transaction_data.date_created}
					</Typography.Text>
				</div>

				<div className={classes['img-notif-transaction']}>
					<ImageC
						src={props.transaction_data.banner_image}
						height={40}
						width={40}
						alt={props.transaction_data.title}
						objectFit="cover"
						defaultSrc={getDefaultImg(props.transaction_data?.module)}
					/>
				</div>
			</WrapNotif>
		</>
	);
};

export default TransactionMenu;
